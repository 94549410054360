/**
=========================================================
* DakdoukGlobalGate Admin React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// DakdoukGlobalGate Admin React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// DakdoukGlobalGate Admin React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

function CoverLayout({ color, header, title, description, image, top, children }) {
  return (
    <PageLayout background="white">
      
      <Grid
        container
        justifyContent="center"
        sx={{
          minHeight: "75vh",
          margin: 0,
        }}
      >
        <Grid item xs={11} sm={8} md={5} xl={3}>
          <SoftBox mt={top}>
            <SoftBox pt={3} px={3}>
              {!header ? (
                <>
                  <SoftBox mb={1}>
                    <SoftTypography variant="h3" fontWeight="bold" color={color} textGradient>
                      {title}
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography variant="body2" fontWeight="regular" color="text">
                    {description}
                  </SoftTypography>
                </>
              ) : (
                header
              )}
            </SoftBox>
            <SoftBox p={3}>{children}</SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} md={6}> 
  <SoftBox
    height="100%"
    display={{ xs: "none", md: "block" }}
    position="relative"
    right={{ md: "-8rem", xl: "-12rem" }} // Adjust horizontal offset for better alignment
    mr={-8} // Reduce margin for better centering
    sx={{
      transform: "skewX(-8deg)", // Reduce skew for a subtler effect
      overflow: "hidden",
      borderBottomLeftRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Optional: add shadow for depth
    }}
  >
    <SoftBox
      height="100%"
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center", // Center the image for better focus
        transform: "skewX(8deg)", // Match the inner skew with outer box
        borderRadius: "inherit", // Ensure inner radius matches container
      }}
    />
  </SoftBox>
</Grid>

      </Grid>
     
    </PageLayout>
  );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 20,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
