/**
=========================================================
* DakdoukGlobalGate Admin React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base breakpoints for the DakdoukGlobalGate Admin React.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire DakdoukGlobalGate Admin React using thie file.
 */

const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};

export default breakpoints;
