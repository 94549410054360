import { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/mainLogo.jpg";

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleSignIn = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('https://ecom-api-a3zv.onrender.com/user/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      const data = await response.json();
  
      // Save token and roles to localStorage
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('isAdmin', data.user.isAdmin);
      localStorage.setItem('isCeo', data.user.isCeo);

      // Redirect based on role
      if (data.user.isCeo) {
        navigate('/dashboard'); // Redirect CEO to dashboard
      } else if (data.user.isAdmin) {
        navigate('/products'); // Redirect Admin to dashboard (or to another page if you prefer)
      } else {
        navigate('/'); // Redirect to another page if roles are not valid
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError('Login failed. Please check your email and password.');
    }
  };
  
  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your email and password to sign in"
      image={curved9}
    >
      <SoftBox component="form" role="form" onSubmit={handleSignIn}>
        <SoftBox mb={2}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Email
          </SoftTypography>
          <SoftInput type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            Password
          </SoftTypography>
          <SoftInput type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </SoftBox>
        {error && (
          <SoftBox mb={2}>
            <SoftTypography variant="caption" color="error">
              {error}
            </SoftTypography>
          </SoftBox>
        )}
        <SoftButton type="submit" variant="gradient" color="info" fullWidth>
          Sign in
        </SoftButton>
      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
