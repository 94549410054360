import React from 'react';
import ProductList from 'components/Product/product';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

const products = [
  {
    name: 'Product 1',
    description: 'A cool product',
    price: 29.99,
    category: 'Clothes',
    store: 'clothes',
    image: 'https://via.placeholder.com/150',
    variants: [
      {
        color: 'Red',
        sizes: [
          { size: 'S', stock: 10 },
          { size: 'M', stock: 5 },
        ],
      },
    ],
  },
  // Add more products here
];

const Products = () => {
  return (

        <DashboardLayout>
      <ProductList />
      </DashboardLayout>

  );
};

export default Products;
