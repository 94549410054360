import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// DakdoukGlobalGate Admin React components
import SoftBox from "components/SoftBox";

// DakdoukGlobalGate Admin React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// DakdoukGlobalGate Admin React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// DakdoukGlobalGate Admin React routes
import routes from "routes"; // Import your routes

// DakdoukGlobalGate Admin React contexts
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  console.log("Current pathname:", pathname); // Log the current route
  console.log("Layout:", layout); // Log the current layout to check if it's set correctly

  const checkUserRole = (role) => {
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    const isCeo = localStorage.getItem("isCeo") === "true";
  
    // If user is CEO, allow access to everything
    if (isCeo) {
      return true;
    }
  
    // Allow admin role access only if user isAdmin
    if (role === "admin") {
      return isAdmin;
    }
  
    // Allow CEO role only if user isCeo
    if (role === "ceo") {
      return isCeo;
    }
  
    return false;
  };
  
  // Cache for the RTL
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });
    setRtlCache(cacheRtl);
   
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
      
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => {
    setOpenConfigurator(dispatch, !openConfigurator);
   
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
   
  }, [pathname]);

  // Logout or handle navigation on invalid role
  useEffect(() => {
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    const isCeo = localStorage.getItem("isCeo") === "true";

   

    if (!isAdmin && !isCeo && pathname !== "/sign-in") {
  
      navigate("/sign-in");
    }
  }, [navigate, pathname]);

  // Filter routes based on user role, but keep sign-in accessible for all
  const getRoutes = (allRoutes) => {
    const filteredRoutes = allRoutes.filter(
      (route) => route.role === "public" || checkUserRole(route.role)
    );

    return filteredRoutes.map((route) => {
      if (route.collapse) {

        return getRoutes(route.collapse);
      }

      if (route.route) {
      
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  };

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  // Conditionally hide Sidenav on Sign In or Sign Up pages
  const showSidenav = pathname !== "/sign-in" && pathname !== "/sign-up"; // Add more paths if needed
  console.log("Show Sidenav:", showSidenav); // Log whether sidenav should be shown

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={themeRTL}>
        <CssBaseline />
        {showSidenav && layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brandName="DakdoukGlobalGate Admin"
              routes={routes}
              checkUserRole={checkUserRole}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/sign-in" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {showSidenav && layout === "dashboard" && (
        <>
         <Sidenav
  color={sidenavColor}
  brandName="DakdoukGlobalGate Admin"
  routes={routes}
  checkUserRole={checkUserRole} // Ensure this prop is passed here
  onMouseEnter={handleOnMouseEnter}
  onMouseLeave={handleOnMouseLeave}
/>

          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/sign-in" />} />
      </Routes>
    </ThemeProvider>
  );
}
