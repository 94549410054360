import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "../../examples/Tables/Table";
import { Button, Card, Box, Typography, Grid, Divider, CircularProgress } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const OrdersComponent = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [sendingEmail, setSendingEmail] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch("https://ecom-api-a3zv.onrender.com/order/getAll");
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setOrders([]);
      }
    };
    fetchOrders();
  }, []);

  const toggleOrderDetails = (order) => {
    setSelectedOrder(selectedOrder?._id === order._id ? null : order);
  };

  const markAsDelivered = async (id) => {
    try {
      const response = await fetch(`https://ecom-api-a3zv.onrender.com/order/deliver/${id}/deliver`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        setOrders((orders) =>
          orders.map((order) =>
            order._id === id ? { ...order, isDelivered: true, deliveredAt: new Date() } : order
          )
        );
        if (selectedOrder?._id === id) {
          setSelectedOrder({ ...selectedOrder, isDelivered: true, deliveredAt: new Date() });
        }
      } else {
        console.error("Failed to mark order as delivered");
      }
    } catch (error) {
      console.error("Error marking order as delivered:", error);
    }
  };

  const markAsPaid = async (id) => {
    try {
      const response = await fetch(`https://ecom-api-a3zv.onrender.com/order/payment/${id}/pay`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        setOrders((orders) =>
          orders.map((order) =>
            order._id === id ? { ...order, isPaid: true, paidAt: new Date() } : order
          )
        );
        if (selectedOrder?._id === id) {
          setSelectedOrder({ ...selectedOrder, isPaid: true, paidAt: new Date() });
        }
      } else {
        console.error("Failed to mark order as paid");
      }
    } catch (error) {
      console.error("Error marking order as paid:", error);
    }
  };

  const sendConfirmationEmail = async (order) => {
    setSendingEmail(true);
    try {
      const response = await fetch(
        `https://ecom-api-a3zv.onrender.com/order/${order._id}/send-confirmation-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ order }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to send confirmation email.");
      }
      alert("Confirmation email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send confirmation email.");
    } finally {
      setSendingEmail(false);
    }
  };

  const columns = [
    { name: "order_id", align: "left", width: "20%" },
    { name: "total_price", align: "right", width: "20%" },
    { name: "paid", align: "center", width: "15%" },
    { name: "delivered", align: "center", width: "15%" },
    { name: "action", align: "center", width: "30%" },
  ];

  const generateOrderRows = (order) => {
    const mainRow = {
      order_id: order._id,
      total_price: `$${order.totalPrice.toFixed(2)}`,
      paid: order.isPaid ? "Yes" : "No",
      delivered: order.isDelivered ? "Yes" : "No",
      action: (
        <Button
          onClick={() => toggleOrderDetails(order)}
          variant="outlined"
          color="primary"
          size="small"
        >
          {selectedOrder?._id === order._id ? "Hide Details" : "View Details"}
        </Button>
      ),
      hasBorder: selectedOrder?._id !== order._id,
    };

    if (selectedOrder?._id === order._id) {
      return [
        mainRow,
        {
          order_id: (
            <SoftBox py={2} backgroundColor="#f8f9fa">
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <SoftTypography variant="caption" fontWeight="medium">
                    Customer Details
                  </SoftTypography>
                  <SoftBox mt={1}>
                    <SoftTypography>Name: {order.user?.name || "N/A"}</SoftTypography>
                    <SoftTypography>Email: {order.user?.email || "N/A"}</SoftTypography>
                    <SoftTypography>Phone: {order.user?.phone || "N/A"}</SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SoftTypography variant="caption" fontWeight="medium">
                    Shipping Details
                  </SoftTypography>
                  <SoftBox mt={1}>
                    <SoftTypography>
                      Address:{" "}
                      {`${order.shippingAddress.address}, ${order.shippingAddress.city}, ${order.shippingAddress.country}`}
                    </SoftTypography>
                    <SoftTypography>Payment Method: {order.paymentMethod}</SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SoftTypography variant="caption" fontWeight="medium">
                    Price Details
                  </SoftTypography>
                  <SoftBox mt={1}>
                    <SoftTypography>Items: ${order.itemsPrice.toFixed(2)}</SoftTypography>
                    <SoftTypography>Shipping: ${order.shippingPrice.toFixed(2)}</SoftTypography>
                    <SoftTypography>Tax: ${order.taxPrice.toFixed(2)}</SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                  <SoftBox mt={2}>
                    <SoftTypography variant="caption" fontWeight="medium">
                      Order Items
                    </SoftTypography>
                    {order.orderItems.map((item, index) => (
                      <SoftTypography key={index}>
                        {item.product.name} x {item.quantity} - ${item.price.toFixed(2)}
                      </SoftTypography>
                    ))}
                  </SoftBox>
                </Grid>
                <Grid item xs={12}>
                  <SoftBox mt={2} display="flex" gap={2}>
                    {!order.isPaid && (
                      <Button
                        onClick={() => markAsPaid(order._id)}
                        variant="contained"
                        color="success"
                        size="small"
                      >
                        Mark as Paid
                      </Button>
                    )}
                    {!order.isDelivered && (
                      <Button
                        onClick={() => markAsDelivered(order._id)}
                        variant="contained"
                        color="primary"
                        size="small"
                      >
                        Mark as Delivered
                      </Button>
                    )}
                    <Button
                      onClick={() => sendConfirmationEmail(order)}
                      variant="contained"
                      color="secondary"
                      disabled={sendingEmail}
                      startIcon={sendingEmail && <CircularProgress size={20} />}
                      size="small"
                    >
                      {sendingEmail ? "Sending..." : "Send Confirmation Email"}
                    </Button>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          ),
          total_price: "",
          paid: "",
          delivered: "",
          action: "",
          hasBorder: true,
        },
      ];
    }

    return [mainRow];
  };

  const rows = orders.flatMap((order) => generateOrderRows(order));

  return (
    <DashboardLayout>
      <SoftBox p={3}>
        <SoftBox mb={3}>
          <SoftTypography variant="h4" fontWeight="bold">
            Orders
          </SoftTypography>
        </SoftBox>
        {orders.length === 0 ? (
          <SoftTypography variant="body1" color="secondary">
            No orders available
          </SoftTypography>
        ) : (
          <Card>
            <Table columns={columns} rows={rows} />
          </Card>
        )}
      </SoftBox>
    </DashboardLayout>
  );
};

export default OrdersComponent;
