import React, { useEffect, useState } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// DakdoukGlobalGate Admin React components
import SoftBox from "components/SoftBox";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

function Dashboard() {
  // State for overall statistics
  const [stats, setStats] = useState({
    totalRevenue: 0,
    numberOfCustomers: 0,
    totalSoldProducts: 0,
    revenue: 0,
    totalCredit: 0,
    totalCash: 0,
  });

  const [stat2, setStat2] = useState(0);
  const [dayDebit, setDayDebit] = useState(0);
  const [returned, setReturned] = useState(0);
  const [monthDebit, setMonthDebit] = useState(0);
  const [chartData, setChartData] = useState({
    labels: [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ],
    datasets: [
      {
        label: "Sales",
        data: [30, 50, 80, 40, 70, 90, 100, 120, 110, 130, 140, 150], // Mock data for each month
        color: "info",
      },
    ],
  });

  // Mock data for branch sales stats
  const [branchSalesStats, setBranchSalesStats] = useState([
    {
      branchName: "Rawad Stores",
      monthlyTotalSales: 0,
      dailyCreditSales: 0,
      dailyCashSales: 0,
    },
    {
      branchName: "Kids Toys",
      monthlyTotalSales: 0,
      dailyCreditSales: 0,
      dailyCashSales: 0,
    },
    {
      branchName: "Rawad Dakdouk Fireworks",
      monthlyTotalSales: 0,
      dailyCreditSales: 0,
      dailyCashSales: 0,
    },
  ]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            {/* Dynamic data for today's money */}
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "today's money" }}
                count={`$ ${stats.totalRevenue + dayDebit - returned}`}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            {/* Dynamic data for today's clients */}
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "today's clients" }}
                count={stats.numberOfCustomers}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            {/* Dynamic data for sold products */}
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "sold products" }}
                count={`${stats.totalSoldProducts}`}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>
        
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Monthly Sales" }}
                count={`$ ${stat2 + monthDebit}`}
                icon={{ color: "info", component: "shopping_cart" }}
              />
            </Grid>
          </Grid>
        </SoftBox>

        {/* Display Branch Sales Stats */}
        {branchSalesStats.map((branch, index) => (
          <SoftBox key={index} mb={3}>
            <Typography variant="h6" gutterBottom>
              {branch.branchName}
            </Typography>
            <Grid container spacing={3}>
              {/* Monthly Sales */}
              <Grid item xs={12} sm={4} xl={3}>
                <MiniStatisticsCard
                  title={{ text: ` Monthly Sales` }}
                  count={`$ ${branch.monthlyTotalSales}`}
                  icon={{ color: "info", component: "attach_money" }}
                />
              </Grid>

              {/* Daily Credit Sales */}
              <Grid item xs={12} sm={4} xl={3}>
                <MiniStatisticsCard
                  title={{ text: ` Todays Customers` }}
                  count={`$ ${branch.dailyCreditSales || 0}`} // Fallback to 0 if undefined
                  icon={{ color: "warning", component: "credit_card" }}
                />
              </Grid>

           

              {/* Total Sales for the Day (Credit + Cash) */}
              <Grid item xs={12} sm={4} xl={3}>
                <MiniStatisticsCard
                  title={{ text: ` Total Daily Sales` }}
                  count={`$ ${(branch.dailyCreditSales || 0) + (branch.dailyCashSales || 0)}`} // Fallback to 0 if undefined
                  icon={{ color: "primary", component: "paid" }}
                />
              </Grid>
            </Grid>
          </SoftBox>
        ))}

        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              {chartData.labels.length > 0 && (
                <GradientLineChart
                  title="Monthly Sales"
                  description="A detailed view of monthly sales data."
                  height="400px"
                  chart={chartData}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={5}>
              <OrderOverview />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default Dashboard;
