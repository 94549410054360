import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Card, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import curved6 from "assets/images/curved-images/curved14.jpg";

function SignUp() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhone] = useState("");  // Added phone number state
  const [isAdmin, setIsAdmin] = useState(false);
  const [isCeo, setIsCeo] = useState(false);

  const validateForm = () => {
    if (!name || !email || !password || !phoneNumber) {  // Added phone to validation
      return false;
    }
    return true;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      Swal.fire({
        icon: 'warning',
        title: 'Incomplete Form',
        text: 'Please fill all fields.',
      });
      return;
    }

    try {
      const response = await axios.post('https://ecom-api-a3zv.onrender.com/user/register', {
        name,
        email,
        password,
        phoneNumber,
        isAdmin,
        isCeo
      });

      console.log('Signup successful:', response.data);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Registration successful!',
      });
      // Reset form
      setName("");
      setEmail("");
      setPassword("");
      setPhone("");  // Reset phone field
      setIsAdmin(false);
      setIsCeo(false);
    } catch (error) {
      console.error('Signup failed:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Registration failed. Please try again.',
      });
    }
  };

  return (
    <BasicLayout
      title="Welcome!"
      description="Use these awesome forms to login or create a new account in your project for free."
      image={curved6}
    >
      <Card>
        <SoftBox p={3} mb={1} textAlign="center">
          <SoftTypography variant="h5" fontWeight="medium">
            Register
          </SoftTypography>
        </SoftBox>
        <SoftBox pt={2} pb={3} px={3}>
          <SoftBox component="form" role="form" onSubmit={handleSignUp}>
            <SoftBox mb={2}>
              <SoftInput value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
            </SoftBox>
            <SoftBox mb={2}>
              <SoftInput type="tel" value={phoneNumber} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" />  {/* Added phone input */}
            </SoftBox>
            <SoftBox mb={2}>
              <FormControl fullWidth>
                <InputLabel>Admin</InputLabel>
                <Select value={isAdmin} onChange={(e) => setIsAdmin(e.target.value)}>
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </FormControl>
            </SoftBox>
            <SoftBox mb={2}>
              <FormControl fullWidth>
                <InputLabel>CEO</InputLabel>
                <Select value={isCeo} onChange={(e) => setIsCeo(e.target.value)}>
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </FormControl>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton type="submit" variant="gradient" color="dark" fullWidth>
                Sign Up
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
