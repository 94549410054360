import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import SoftBadge from "components/SoftBadge";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function UsersTable() {
  const { t } = useTranslation();
  const [usersData, setUsersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRole, setSelectedRole] = useState("all");

  useEffect(() => {
    async function fetchUsersData() {
      try {
        const response = await axios.get('https://ecom-api-a3zv.onrender.com/user/getUsers');
        const data = response.data.data;

        const transformedData = data.map(user => {
          let role = "normal";
          if (user.isAdmin) role = "admin";
          else if (user.isCeo) role = "ceo";

          return {
            id: user._id,
            name: user.name,
            email: user.email,
            phone: user.phone || "N/A",
            role,
            isVerified: user.isVerified
              ? <SoftBadge variant="gradient" badgeContent={t("Verified")} color="success" size="xs" container />
              : <SoftBadge variant="gradient" badgeContent={t("Not Verified")} color="warning" size="xs" container />,
          };
        });
        setUsersData(transformedData);
        setFilteredData(transformedData); // Initialize filteredData with all users
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    fetchUsersData();
  }, []); // Remove `t` from dependency array to prevent infinite fetching

  const handleRoleChange = (event) => {
    const role = event.target.value;
    setSelectedRole(role);

    if (role === "all") {
      setFilteredData(usersData);
    } else {
      const filtered = usersData.filter(user => user.role === role);
      setFilteredData(filtered);
    }
  };

  const columns = [
    { name: "name", align: "left", label: t("Name") },
    { name: "email", align: "left", label: t("Email") },
    { name: "phone", align: "left", label: t("Phone") },
    { name: "role", align: "left", label: t("Role") },
    { name: "isVerified", align: "center", label: t("Verified Status") },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card className="table-card">
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} className="table-header">
              <SoftTypography variant="h6">{t("Users")}</SoftTypography>
              {/* Filter dropdown */}
              <Select
                value={selectedRole}
                onChange={handleRoleChange}
                displayEmpty
                variant="outlined"
                style={{ width: 200 }}
              >
                <MenuItem value="all">{t("All Roles")}</MenuItem>
                <MenuItem value="normal">{t("Normal")}</MenuItem>
                <MenuItem value="ceo">{t("CEO")}</MenuItem>
                <MenuItem value="admin">{t("Admin")}</MenuItem>
              </Select>
            </SoftBox>
            <SoftBox
              className="table-content"
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={filteredData} />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default UsersTable;
