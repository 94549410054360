import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, TextField, Button, Grid, Box, Typography, Select, MenuItem, InputLabel, FormControl, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

const CategoryPage = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({ name: '', store: '' });
  const [editCategory, setEditCategory] = useState(null); // State for editing category
  const [isEditOpen, setIsEditOpen] = useState(false); // State for edit dialog

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://ecom-api-a3zv.onrender.com/category/getCategories'); // Adjust the URL if needed
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  // Map store value to display name
  const getStoreDisplayName = (store) => {
    switch (store) {
      case 'clothes':
        return 'Rawad Stores';
      case 'toys':
        return 'Kids Toys';
      case 'fireworks':
        return 'Rawad Dakdouk Fireworks';
      default:
        return store;
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCategory({ ...newCategory, [name]: value });
  };

  // Handle adding new category
  const handleAddCategory = async (e) => {
    e.preventDefault();

    if (newCategory.name && newCategory.store) {
      try {
        const response = await axios.post('https://ecom-api-a3zv.onrender.com/category/create', newCategory); // Adjust the URL if needed
        setCategories([...categories, response.data]);

        setNewCategory({ name: '', store: '' });
      } catch (error) {
        console.error('Error adding category:', error);
      }
    }
  };

  // Handle delete category
  const handleDeleteCategory = async (id) => {
    try {
      await axios.delete(`https://ecom-api-a3zv.onrender.com/category/${id}`);
      setCategories(categories.filter(category => category._id !== id));
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  // Handle open edit dialog
  const handleEditCategory = (category) => {
    setEditCategory(category); // Set the selected category to edit
    setIsEditOpen(true); // Open the dialog
  };

  // Handle close edit dialog
  const handleCloseEdit = () => {
    setIsEditOpen(false);
    setEditCategory(null);
  };

  // Handle updating category
  const handleUpdateCategory = async () => {
    try {
      const response = await axios.put(`https://ecom-api-a3zv.onrender.com/category/${editCategory._id}`, editCategory);
      setCategories(categories.map(category =>
        category._id === editCategory._id ? response.data : category
      ));
      handleCloseEdit(); // Close the dialog after update
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  // Handle input changes in edit form
  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditCategory({ ...editCategory, [name]: value });
  };

  return (
    <DashboardLayout>
      <Box sx={{ padding: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Add New Category
                </Typography>
                <form onSubmit={handleAddCategory}>
                  <TextField
                    label="Category Name"
                    name="name"
                    value={newCategory.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    margin="normal"
                  />
                  <FormControl fullWidth margin="normal" required>
                    <InputLabel>Store</InputLabel>
                    <Select
                      name="store"
                      value={newCategory.store}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="clothes">Rawad Stores</MenuItem>
                      <MenuItem value="toys">Kids Toys</MenuItem>
                      <MenuItem value="fireworks">Rawad Dakdouk Fireworks</MenuItem>
                    </Select>
                  </FormControl>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Add Category
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Categories List
                </Typography>
                {categories.length > 0 ? (
                  categories.map((category, index) => (
                    <Box key={index} sx={{ marginBottom: 2 }}>
                      <Typography variant="body1">{category.name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        Store: {getStoreDisplayName(category.store)}
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{ marginRight: 1 }}
                        onClick={() => handleEditCategory(category)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDeleteCategory(category._id)}
                      >
                        Delete
                      </Button>
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No categories available.
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Edit Category Dialog */}
      {editCategory && (
        <Dialog open={isEditOpen} onClose={handleCloseEdit}>
          <DialogTitle>Edit Category</DialogTitle>
          <DialogContent>
            <TextField
              label="Category Name"
              name="name"
              value={editCategory.name}
              onChange={handleEditInputChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Store</InputLabel>
              <Select
                name="store"
                value={editCategory.store}
                onChange={handleEditInputChange}
              >
                <MenuItem value="clothes">Rawad Stores</MenuItem>
                <MenuItem value="toys">Kids Toys</MenuItem>
                <MenuItem value="fireworks">Rawad Dakdouk Fireworks</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpdateCategory} color="primary">
              Update Category
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </DashboardLayout>
  );
};

export default CategoryPage;
