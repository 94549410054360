import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Divider,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
} from '@mui/material';
import axios from 'axios';

const ProductList = () => {
  const [productList, setProductList] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filters, setFilters] = useState({ store: '', category: '' });
  const [searchProductId, setSearchProductId] = useState('');
  const [editProduct, setEditProduct] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);

  const getStoreDisplayName = (store) => {
    switch (store) {
      case 'clothes':
        return 'Rawad Stores';
      case 'toys':
        return 'Kids Toys';
      case 'fireworks':
        return 'Rawad Dakdouk Fireworks';
      default:
        return store;
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://ecom-api-a3zv.onrender.com/product/getProd');
        setProductList(response.data);
        setFilteredProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  const fetchCategoriesByStore = async (store) => {
    try {
      const response = await axios.get(`https://ecom-api-a3zv.onrender.com/category/store/${store}`);
      setFilteredCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setFilteredCategories([]);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });

    if (name === 'store') {
      if (value) {
        fetchCategoriesByStore(value);
      } else {
        setFilteredCategories([]);
      }

      const filtered = productList.filter((product) =>
        product.store.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else if (name === 'category') {
      const filtered = productList.filter((product) =>
        product.category.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  const filterProductsById = () => {
    const filtered = productList.filter(
      (product) => product.productID === searchProductId
    );
    setFilteredProducts(filtered);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://ecom-api-a3zv.onrender.com/product/${id}`);
      const updatedProductList = productList.filter((product) => product._id !== id);
      setProductList(updatedProductList);
      setFilteredProducts(updatedProductList);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleEdit = (product) => {
    setEditProduct(product);
    setIsEditOpen(true);
  };

  const handleCloseEdit = () => {
    setIsEditOpen(false);
    setEditProduct(null);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`https://ecom-api-a3zv.onrender.com/product/${editProduct._id}`, editProduct);
      setIsEditOpen(false);
      const updatedProductList = productList.map((product) =>
        product._id === editProduct._id ? editProduct : product
      );
      setProductList(updatedProductList);
      setFilteredProducts(updatedProductList);
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleFeaturedToggle = (e) => {
    setEditProduct({ ...editProduct, featured: e.target.checked });
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Filter Controls */}
      <Box sx={{ marginBottom: 3, display: 'flex', gap: 2 }}>
        {/* Store Filter */}
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Store</InputLabel>
          <Select
            label="Store"
            name="store"
            value={filters.store}
            onChange={handleFilterChange}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="toys">Kids Toys</MenuItem>
            <MenuItem value="clothes">Rawad Stores</MenuItem>
            <MenuItem value="fireworks">Rawad Dakdouk Fireworks</MenuItem>
          </Select>
        </FormControl>

        {/* Category Filter */}
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Category</InputLabel>
          <Select
            label="Category"
            name="category"
            value={filters.category}
            onChange={handleFilterChange}
            disabled={!filters.store}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {filteredCategories.map((category) => (
              <MenuItem key={category._id} value={category.name}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Product ID Filter */}
        <TextField
          label="Product ID"
          variant="outlined"
          value={searchProductId}
          onChange={(e) => setSearchProductId(e.target.value)}
          sx={{ flex: 1 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={filterProductsById}
        >
          Search
        </Button>
      </Box>

      {/* Product List */}
      <Grid container spacing={4}>
        {filteredProducts.map((product, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
              {/* Product Details */}
              {product.images && product.images.length > 0 && (
                <Box sx={{ position: 'relative', height: 300 }}>
                  <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={true}
                    pagination={{
                      clickable: true,
                      dynamicBullets: true,
                    }}
                    loop={true}
                    style={{ height: '100%' }}
                  >
                    {product.images.map((image, imgIndex) => (
                      <SwiperSlide key={imgIndex}>
                        <img
                          src={image}
                          alt={`${product.name} image ${imgIndex + 1}`}
                          style={{
                            width: '100%',
                            height: '300px',
                            objectFit: 'cover',
                          }}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Box>
              )}
              <CardContent>
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {product.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {product.description}
                </Typography>
                <Typography variant="h6" color="primary" fontWeight="bold">
                  ${product.price.toFixed(2)}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  <strong>Category:</strong> {product.category.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Store:</strong> {getStoreDisplayName(product.store)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Product ID:</strong> {product.productID}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEdit(product)}
                  sx={{ marginRight: 1 }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(product._id)}
                >
                  Delete
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Edit Product Dialog */}
      {editProduct && (
        <Dialog open={isEditOpen} onClose={handleCloseEdit}>
          <DialogTitle>Edit Product</DialogTitle>
          <DialogContent>
            <TextField
              label="Product ID"
              value={editProduct.productID}
              onChange={(e) =>
                setEditProduct({ ...editProduct, productID: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Name"
              value={editProduct.name}
              onChange={(e) =>
                setEditProduct({ ...editProduct, name: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Price"
              type="number"
              value={editProduct.price}
              onChange={(e) =>
                setEditProduct({
                  ...editProduct,
                  price: parseFloat(e.target.value),
                })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Description"
              value={editProduct.description}
              onChange={(e) =>
                setEditProduct({
                  ...editProduct,
                  description: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              multiline
              rows={3}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={editProduct.featured}
                  onChange={handleFeaturedToggle}
                />
              }
              label="Featured"
              sx={{ marginTop: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="primary">
              Update Product
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productID: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      price: PropTypes.number.isRequired,
      category: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      store: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string),
      featured: PropTypes.bool,
    }).isRequired
  ),
};

export default ProductList;
