import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, TextField, Button, Select, MenuItem, Grid, Box, InputLabel, FormControl, Snackbar, IconButton, Alert } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

const AddProductForm = () => {
  const [product, setProduct] = useState({
    productID: '',
    name: '',
    description: '',
    price: '',
    category: '',
    store: '',
    images: [],
    variants: [{ color: '', sizes: [{ size: '', stock: '' }] }],
  });

  const [categories, setCategories] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false); // Alert state for max image limit

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });

    if (name === 'store') {
      fetchCategories(value);
    }
  };

  // Handle multiple image uploads with a maximum of 4
  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);


    setProduct((prevState) => ({
      ...prevState,
      images: [...prevState.images, ...selectedFiles],
    }));
  };

  // Remove an image from the uploaded list
  const handleRemoveImage = (index) => {
    setProduct((prevState) => {
      const newImages = [...prevState.images];
      newImages.splice(index, 1);
      return { ...prevState, images: newImages };
    });
  };

  // Fetch categories based on the selected store
  const fetchCategories = async (store) => {
    try {
      const response = await axios.get(`https://ecom-api-a3zv.onrender.com/category/store/${store}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const addVariant = () => {
    setProduct({
      ...product,
      variants: [...product.variants, { color: '', sizes: [{ size: '', stock: '' }] }],
    });
  };

  const addSize = (variantIndex) => {
    const newVariants = [...product.variants];
    newVariants[variantIndex].sizes.push({ size: '', stock: '' });
    setProduct({ ...product, variants: newVariants });
  };

  const handleVariantChange = (index, field, value) => {
    const newVariants = [...product.variants];
    newVariants[index][field] = value;
    setProduct({ ...product, variants: newVariants });
  };

  const handleSizeChange = (variantIndex, sizeIndex, field, value) => {
    const newVariants = [...product.variants];
    newVariants[variantIndex].sizes[sizeIndex][field] = value;
    setProduct({ ...product, variants: newVariants });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('productID', product.productID);
    formData.append('name', product.name);
    formData.append('description', product.description);
    formData.append('price', product.price);
    formData.append('category', product.category);
    formData.append('store', product.store);
    formData.append('variants', JSON.stringify(product.variants));

    product.images.forEach((image, index) => {
      formData.append(`images`, image);
    });

    try {
      const response = await axios.post('https://ecom-api-a3zv.onrender.com/product/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Product created successfully:', response.data);

      setProduct({
        productID: '',
        name: '',
        description: '',
        price: '',
        category: '',
        store: '',
        images: [],
        variants: [{ color: '', sizes: [{ size: '', stock: '' }] }],
      });

      setSuccessAlert(true);
    } catch (error) {
      console.error('Error creating product:', error);
    }
  };

  const handleCloseAlert = () => {
    setSuccessAlert(false);
    setErrorAlert(false);
  };

  return (
    <DashboardLayout>
      <Box sx={{ padding: 3 }}>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Product ID"
                    name="productID"
                    value={product.productID}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Product Name"
                    name="name"
                    value={product.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Price"
                    name="price"
                    type="number"
                    value={product.price}
                    onChange={handleInputChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    name="description"
                    value={product.description}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Store</InputLabel>
                    <Select
                      name="store"
                      value={product.store}
                      onChange={handleInputChange}
                      required
                    >
                      <MenuItem value="clothes">Rawad Stores</MenuItem>
                      <MenuItem value="toys">Kids Toys</MenuItem>
                      <MenuItem value="fireworks">Rawad Dakdouk Fireworks</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select
                      name="category"
                      value={product.category}
                      onChange={handleInputChange}
                      required
                    >
                      {categories.map((category) => (
                        <MenuItem key={category._id} value={category._id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                    fullWidth
                  >
                    Upload Product Images
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      multiple
                      onChange={handleImageChange}
                    />
                  </Button>
                </Grid>
                
                {/* Display uploaded images with delete option */}
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: 2 }}>
                    {product.images.map((image, index) => (
                      <Box key={index} sx={{ position: 'relative', width: 100, height: 100 }}>
                        <img
                          src={URL.createObjectURL(image)}
                          alt="Uploaded Preview"
                          style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px' }}
                        />
                        <IconButton
                          onClick={() => handleRemoveImage(index)}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bgcolor: 'rgba(255, 255, 255, 0.7)',
                            '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                </Grid>

                {/* Variants Section */}
                {product.variants.map((variant, variantIndex) => (
                  <Box key={variantIndex} sx={{ marginTop: 3, padding: 2, border: '1px solid #ccc', borderRadius: '8px' }}>
                    <TextField
                      label="Color"
                      value={variant.color}
                      onChange={(e) => handleVariantChange(variantIndex, 'color', e.target.value)}
                      fullWidth
                      required
                      margin="normal"
                    />
                    {variant.sizes.map((size, sizeIndex) => (
                      <Grid container spacing={2} key={sizeIndex} sx={{ marginBottom: 2 }}>
                        <Grid item xs={6}>
                          <TextField
                            label="Size"
                            value={size.size}
                            onChange={(e) => handleSizeChange(variantIndex, sizeIndex, 'size', e.target.value)}
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Stock"
                            type="number"
                            value={size.stock}
                            onChange={(e) => handleSizeChange(variantIndex, sizeIndex, 'stock', e.target.value)}
                            fullWidth
                            required
                          />
                        </Grid>
                      </Grid>
                    ))}
                    <Button variant="outlined" onClick={() => addSize(variantIndex)}>
                      Add Size
                    </Button>
                  </Box>
                ))}
                <Grid item xs={12}>
                  <Button variant="outlined" onClick={addVariant} fullWidth>
                    Add Variant (Color)
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
        <Snackbar open={errorAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
          <Alert severity="error" onClose={handleCloseAlert}>
            You can upload a maximum of 4 images.
          </Alert>
        </Snackbar>
        <Snackbar open={successAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
          <Alert severity="success" onClose={handleCloseAlert}>
            Product created successfully!
          </Alert>
        </Snackbar>
      </Box>
    </DashboardLayout>
  );
};

export default AddProductForm;
