import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card, CardContent, Button, Grid, Box, Typography, CircularProgress, IconButton } from '@mui/material';
import SoftBox from 'components/SoftBox'; // Soft UI component
import SoftTypography from 'components/SoftTypography'; // Soft UI component
import SoftButton from 'components/SoftButton'; // Soft UI component
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DeleteIcon from '@mui/icons-material/Delete';

const ContactSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Fetch submissions from the backend
  useEffect(() => {
    const fetchSubmissions = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://ecom-api-a3zv.onrender.com/contact/submissions'); // Replace with your API endpoint
        setSubmissions(response.data);
      } catch (err) {
        setError('Failed to fetch submissions');
        console.error('Error fetching submissions:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  // Handle deletion of a submission
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://ecom-api-a3zv.onrender.com/contact/${id}`); // Adjust the URL to your delete API
      setSubmissions(submissions.filter(submission => submission._id !== id)); // Remove the deleted item from the state
    } catch (err) {
      console.error('Error deleting submission:', err);
      setError('Failed to delete submission');
    }
  };

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SoftTypography variant="h4" textAlign="center" gutterBottom>
              Contact Us Submissions
            </SoftTypography>
            {loading ? (
              <Box textAlign="center">
                <CircularProgress />
              </Box>
            ) : error ? (
              <SoftTypography variant="h6" color="error" textAlign="center">
                {error}
              </SoftTypography>
            ) : submissions.length === 0 ? (
              <SoftTypography variant="h6" textAlign="center">
                No submissions available.
              </SoftTypography>
            ) : (
              <Grid container spacing={2}>
                {submissions.map((submission) => (
                  <Grid item xs={12} md={6} key={submission._id}>
                    <Card>
                      <CardContent>
                        <SoftTypography variant="h6" gutterBottom>
                          {submission.name}
                        </SoftTypography>
                        <SoftTypography variant="body1" color="textSecondary">
                          {submission.email}
                        </SoftTypography>
                        <SoftTypography variant="body2" sx={{ marginBottom: 2 }}>
                          {submission.message}
                        </SoftTypography>
                        <SoftTypography variant="caption" color="textSecondary">
                          Submitted on: {new Date(submission.createdAt).toLocaleString()}
                        </SoftTypography>
                        <Box display="flex" justifyContent="space-between" mt={2}>
                          <SoftButton variant="gradient" color="info" size="small">
                            View Details
                          </SoftButton>
                          <IconButton
                            color="error"
                            onClick={() => handleDelete(submission._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
};

export default ContactSubmissions;
