import React, { useEffect, useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import { Chart, LinearScale, CategoryScale, PointElement, LineElement } from 'chart.js'; // Import the required elements and scales
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import gradientChartLine from "assets/theme/functions/gradientChartLine";
import colors from "assets/theme/base/colors";

// Register the required scales and elements for the chart
Chart.register(LinearScale, CategoryScale, PointElement, LineElement);

const configs = (labels, datasets) => ({
  data: {
    labels: labels,
    datasets: datasets,
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
      },
      x: {
        type: "category",
      },
    },
  },
});

function GradientLineChart({ title, description, height, chart }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({ data: {}, options: {} });

  useEffect(() => {
    if (chart && chart.labels) {
      const chartDatasets = (chart.datasets || []).map((dataset) => {
        const backgroundColor = chartRef.current?.children[0]
          ? gradientChartLine(
              chartRef.current.children[0],
              colors[dataset.color]?.main || colors.dark.main
            )
          : colors[dataset.color]?.main || colors.dark.main;

        return {
          ...dataset,
          tension: 0.4,
          pointRadius: 0,
          borderWidth: 3,
          borderColor: colors[dataset.color]?.main || colors.dark.main,
          fill: true,
          maxBarThickness: 6,
          backgroundColor,
        };
      });

      const newChartData = configs(chart.labels, chartDatasets);
      setChartData(newChartData);
    }
  }, [chart]);

  return (
    <Card>
      <SoftBox p={2}>
        {title && (
          <SoftBox mb={1}>
            <SoftTypography variant="h6">{title}</SoftTypography>
          </SoftBox>
        )}
        {description && (
          <SoftBox mb={2}>
            <SoftTypography component="div" variant="button" fontWeight="regular" color="text">
              {description}
            </SoftTypography>
          </SoftBox>
        )}
        <SoftBox ref={chartRef} sx={{ height }}>
          {chartData.data && Object.keys(chartData.data).length > 0 && (
            <Line data={chartData.data} options={chartData.options} />
          )}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of GradientLineChart
GradientLineChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
  chart: {
    labels: [],
    datasets: [
      {
        label: "Default Dataset",
        data: [],
        color: "dark",
      },
    ],
  },
};

// Typechecking props for the GradientLineChart
GradientLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.shape({
    labels: PropTypes.array,
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        data: PropTypes.array,
        color: PropTypes.string,
      })
    ),
  }),
};

export default GradientLineChart;
