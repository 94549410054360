import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Modal,
  Box,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const DiscountsComponent = () => {
  const [discounts, setDiscounts] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [newDiscount, setNewDiscount] = useState({
    productId: "",
    description: "",
    discountAmount: "",
    startDate: "",
    endDate: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchProductId, setSearchProductId] = useState("");

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const response = await axios.get("https://ecom-api-a3zv.onrender.com/discount/discount");
        setDiscounts(response.data.discounts || []);
      } catch (error) {
        console.error("Error fetching discounts:", error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await axios.get("https://ecom-api-a3zv.onrender.com/product/getProd");
        const fetchedProducts = response.data || [];
        setProducts(fetchedProducts);
        setFilteredProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchDiscounts();
    fetchProducts();
  }, []);
  const addDiscount = async () => {
    try {
      const response = await axios.post("https://ecom-api-a3zv.onrender.com/discount/add", newDiscount);
      setDiscounts([...discounts, response.data.discount]);
      setNewDiscount({
        productId: "",
        description: "",
        discountAmount: "",
        startDate: "",
        endDate: "",
      });
    } catch (error) {
      console.error("Error adding discount:", error);
    }
  };
  
  const filterProductsById = () => {
    const filtered = products.filter((product) =>
      product.productID === searchProductId
    );
    setFilteredProducts(filtered);
  };

  const handleProductIdChange = (event) => {
    setSearchProductId(event.target.value);
  };

  const handleInputChange = (e) => {
    setNewDiscount({
      ...newDiscount,
      [e.target.name]: e.target.value,
    });
  };

  const selectProduct = (productId) => {
    setNewDiscount({ ...newDiscount, productId });
    setIsModalOpen(false);
  };

  return (
    <DashboardLayout>
      <Typography variant="h4" gutterBottom>
        Discounts
      </Typography>

      <TextField
        label="Search by Product ID"
        variant="outlined"
        value={searchProductId}
        onChange={handleProductIdChange}
        onBlur={filterProductsById}
        fullWidth
        sx={{ marginBottom: 2 }}
      />

      {/* Display Discounts */}
      {discounts.length === 0 ? (
        <Typography>No discounts available</Typography>
      ) : (
        discounts.map((discount) => (
          <Card key={discount._id} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="h5">{discount.product?.name || "Unknown Product"}</Typography>
              <Typography>{discount.description}</Typography>
              <Typography>Discount Percentage: {discount.discountAmount} %</Typography>
              <Typography>Start Date: {new Date(discount.startDate).toLocaleDateString()}</Typography>
              <Typography>End Date: {new Date(discount.endDate).toLocaleDateString()}</Typography>
            </CardContent>
          </Card>
        ))
      )}

      {/* Add Discount Form */}
      <Card sx={{ marginTop: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Add New Discount
          </Typography>

          <Button variant="outlined" onClick={() => setIsModalOpen(true)} sx={{ marginBottom: 2 }}>
            Select Product
          </Button>
          {newDiscount.productId && (
            <Typography>Selected Product ID: {newDiscount.productId}</Typography>
          )}

          <TextField
            label="Description"
            name="description"
            value={newDiscount.description}
            onChange={handleInputChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Discount Percentage"
            name="discountAmount"
            value={newDiscount.discountAmount}
            onChange={handleInputChange}
            fullWidth
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Start Date"
            name="startDate"
            type="date"
            value={newDiscount.startDate}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="End Date"
            name="endDate"
            type="date"
            value={newDiscount.endDate}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginBottom: 2 }}
          />

          <Button color="primary" onClick={addDiscount}>
            Add Discount
          </Button>
        </CardContent>
      </Card>

      {/* Product Selection Modal */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Select Product
          </Typography>
          <List sx={{ maxHeight: 400, overflow: 'auto' }}>
            {(filteredProducts || []).map((product) => (
              <ListItem
                key={product._id}
                onClick={() => selectProduct(product.productID)}
                sx={{
                  mb: 1,
                  p: 2,
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={product.images?.[0] || "https://via.placeholder.com/50"}
                  alt={product.name}
                  style={{ width: 50, height: 50, marginRight: 16 }}
                />
                <ListItemText
                  primary={product.name}
                  secondary={`Product ID: ${product.productID}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default DiscountsComponent;
